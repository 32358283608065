import { HttpClient } from "@angular/common/http";
import { Component } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";

const PRO_RELEASE = "2-0";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  dataSource = new MatTableDataSource(PRO_DATA);
  dataSourceAaptiv = new MatTableDataSource(AAPTIV_DATA);
  dataSourceCast = new MatTableDataSource(CAST_DATA);
  dataSourceStudio = new MatTableDataSource(STUDIO_DATA);
  dataSourceTV = new MatTableDataSource(TV_DATA);

  displayedColumns = ["name", "develop", "staging", "production"]; // "qa"

  PRO_RELEASE = PRO_RELEASE;

  constructor(public http: HttpClient) {}

  getData(url: string) {
    return this.http.get(url);
  }
}

export interface Product {
  id: string;
  name: string;
  base?: string;
  releaseVersion?: string;
  netlify?: boolean;
  hasDevelop?: boolean;
  hasQA?: boolean;
  hasStaging?: boolean;
  hasProduction?: boolean;
  last_develop_date?: string;
  last_develop_commit?: string;
  last_qa_date?: string;
  last_qa_commit?: string;
  last_staging_date?: string;
  last_staging_commit?: string;
  last_production_date?: string;
  last_production_commit?: string;
  commit_message?: string;
  customDevelopUrl?: string;
  customDevelopUrlNew?: string;
  customQAUrl?: string;
  customStagingUrl?: string;
  customStagingUrlNew?: string;
  customProductionUrl?: string;
  customProductionUrlNew?: string;
}

const AAPTIV_DATA: Product[] = [
  {
    id: "AAPTIV-MY",
    name: "User Portal",
    base: "aaptivmy",
    customStagingUrl: "https://my.aapdev.com",
    customProductionUrl: "https://my.aaptiv.com",
  },
  {
    id: "AAPTIV-WORDPRESS",
    name: "Wordpress",
    base: "aaptivwordpress",
    customProductionUrl: "https://aaptiv.com/wp-admin",
  },
  {
    id: "AAPTIV-CMS",
    name: "CMS",
    base: "aaptivcms",
    customStagingUrl: "https://cms.aapdev.com",
    customProductionUrl: "https://cms.aaptiv.com",
  },
  {
    id: "AAPTIV-EMAIL-PREFERENCE",
    name: "EMAIL PREFERENCE",
    base: "aaptiveeemailpreference",
    customStagingUrl: "https://emailpreferencecenter.aapdev.com",
    customProductionUrl: "https://emailpreferencecenter.aaptiv.com"
  }
]

const PRO_DATA: Product[] = [
  {
    id: "PEAR-PRO",
    name: "PEAR",
    base: "pear",
    customDevelopUrl: "https://admin-develop.pearhealthlabs.com",
    customStagingUrl: "https://admin-staging.pearhealthlabs.com",
    customProductionUrl: "https://admin.pearhealthlabs.com",
    releaseVersion: PRO_RELEASE,
  },
  {
    id: "LF-PRO",
    name: "LF",
    base: "lf",
    customDevelopUrl: "https://lf-admin-develop.pearhealthlabs.com",
    customStagingUrl: "https://lf-admin-staging.pearhealthlabs.com",
    customProductionUrl: "https://lf-admin.pearhealthlabs.com",
    releaseVersion: PRO_RELEASE,
  },
  {
    id: "AAPTIV-PRO",
    name: "AAPTIV",
    base: "aaptiv",
    customDevelopUrl: "https://admin-develop.aapdev.com",
    customStagingUrl: "https://admin.aapdev.com",
    customProductionUrl: "https://admin.aaptiv.com",
    releaseVersion: PRO_RELEASE,
  },
  {
    id: "ONEPASS-PRO",
    name: "ONEPASS",
    base: "ONEPASS",
    customDevelopUrl: "https://onepass-admin-develop.aapdev.com",
    customStagingUrl: "https://onepass-admin.aapdev.com",
    customProductionUrl: "https://onepass-admin.aaptiv.com",
    releaseVersion: PRO_RELEASE,
  }
];

const STUDIO_DATA: Product[] = [
  {
    id: "PEAR-STUDIO",
    name: "PEAR",
    base: "pear",
    customDevelopUrl: "https://studio-develop.pearhealthlabs.com",
    customStagingUrl: "https://studio-staging.pearhealthlabs.com",
    customProductionUrl: "https://studio.pearhealthlabs.com",
    releaseVersion: PRO_RELEASE,
  },
  {
    id: "AAPTIV-STUDIO",
    name: "AAPTIV",
    base: "aaptiv",
    customDevelopUrl: "https://studio-develop.aapdev.com",
    customStagingUrl: "https://studio.aapdev.com",
    customProductionUrl: "https://studio.aaptiv.com",
    releaseVersion: PRO_RELEASE,
  },
  {
    id: "LF-STUDIO",
    name: "LF",
    base: "lf",
    customDevelopUrl: "https://lf-studio-develop.pearhealthlabs.com",
    customStagingUrl: "https://lf-studio-staging.pearhealthlabs.com",
    customProductionUrl: "https://lf-studio.pearhealthlabs.com",
    releaseVersion: PRO_RELEASE,
  }
];

const CAST_DATA: Product[] = [
  {
    id: "PEAR-CAST",
    name: "PEAR",
    base: "pear",
    customDevelopUrl: "https://studio-cast-develop.pearhealthlabs.com",
    customStagingUrl: "https://studio-cast-staging.pearhealthlabs.com",
    customProductionUrl: "https://studio-cast.pearhealthlabs.com",
    releaseVersion: PRO_RELEASE,
  },
  {
    id: "AAPTIV-CAST",
    name: "AAPTIV",
    base: "aaptiv",
    customDevelopUrl: "https://studio-cast-develop.aapdev.com",
    customStagingUrl: "https://studio-cast.aapdev.com",
    customProductionUrl: "https://studio-cast.aaptiv.com",
    releaseVersion: PRO_RELEASE,
  },
  {
    id: "LF-CAST",
    name: "LF",
    base: "lf",
    customDevelopUrl: "https://lf-studio-cast-develop.pearhealthlabs.com",
    customStagingUrl: "https://lf-studio-cast-staging.pearhealthlabs.com",
    customProductionUrl: "https://lf-studio-cast.pearhealthlabs.com",
    releaseVersion: PRO_RELEASE,
  }
];

const TV_DATA: Product[] = [
  {
    id: "AAPTIV-TV",
    name: "Aaptiv",
    base: "Aaptiv",
    customDevelopUrl: "https://tv-develop.aapdev.com",
    customStagingUrl: "https://tv.aapdev.com",
    customProductionUrl: "https://tv.aaptiv.com",
    releaseVersion: PRO_RELEASE,
  }
];

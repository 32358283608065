<a class="top-logo" routerLink="./">
  <img src="assets/logo.svg"/>
</a>
<div class="products-list">
  <div class="product-section">
    <h3>Admin</h3>

    <table mat-table [dataSource]="dataSource" matSort>
      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
        <td mat-cell *matCellDef="let row">{{ row.id }}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Site</th>
        <td mat-cell *matCellDef="let row">
          <strong>{{ row.name }}</strong>
        </td>
      </ng-container>

      <ng-container matColumnDef="develop">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Develop</th>
        <td mat-cell *matCellDef="let row">
          <div *ngIf="row.customDevelopUrlNew">
            <a target="_blank" [href]="row.customDevelopUrlNew">Develop - New</a>
            <div style="height:8px"></div>
          </div>
          <div *ngIf="row.customDevelopUrl">
            <a target="_blank" [href]="row.customDevelopUrl">Develop</a>
          </div>

        </td>
      </ng-container>

      <ng-container matColumnDef="staging">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Staging</th>
        <td mat-cell *matCellDef="let row">
          <div *ngIf="row.customStagingUrlNew">
            <a target="_blank" [href]="row.customStagingUrlNew">Staging - New</a>
            <div style="height:8px"></div>
          </div>

          <div *ngIf="row.customStagingUrl">
            <a target="_blank" [href]="row.customStagingUrl">Staging</a>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="production">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Production</th>
        <td mat-cell *matCellDef="let row">
          <div *ngIf="row.customProductionUrlNew">
            <a target="_blank" [href]="row.customProductionUrlNew">Production - New</a>
            <div style="height:8px"></div>
          </div>
          <div *ngIf="row.customProductionUrl">
            <a target="_blank" [href]="[row.customProductionUrl]">Production</a>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <h3>TV</h3>

    <table mat-table [dataSource]="dataSourceTV" matSort>
      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
        <td mat-cell *matCellDef="let row">{{ row.id }}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Site</th>
        <td mat-cell *matCellDef="let row">
          <strong>{{ row.name }}</strong>
        </td>
      </ng-container>

      <ng-container matColumnDef="develop">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Develop</th>
        <td mat-cell *matCellDef="let row">
          <div *ngIf="row.customDevelopUrlNew">
            <a target="_blank" [href]="row.customDevelopUrlNew">Develop - New</a>
            <div style="height:8px"></div>
          </div>
          <div *ngIf="row.customDevelopUrl">
            <a target="_blank" [href]="row.customDevelopUrl">Develop</a>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="staging">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Staging</th>
        <td mat-cell *matCellDef="let row">
          <div *ngIf="row.customStagingUrlNew">
            <a target="_blank" [href]="row.customStagingUrlNew">Staging - New</a>
            <div style="height:8px"></div>
          </div>

          <div *ngIf="row.customStagingUrl">
            <a target="_blank" [href]="row.customStagingUrl">Staging</a>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="production">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Production</th>
        <td mat-cell *matCellDef="let row">
          <div *ngIf="row.customProductionUrlNew">
            <a target="_blank" [href]="row.customProductionUrlNew">Production - New</a>
            <div style="height:8px"></div>
          </div>
          <div *ngIf="row.customProductionUrl">
            <a target="_blank" [href]="[row.customProductionUrl]">Production</a>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>

  <div class="product-section">
    <h3>Studio</h3>

    <table mat-table [dataSource]="dataSourceStudio" matSort>
      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
        <td mat-cell *matCellDef="let row">{{ row.id }}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Site</th>
        <td mat-cell *matCellDef="let row">
          <strong>{{ row.name }}</strong>
        </td>
      </ng-container>

      <ng-container matColumnDef="develop">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Develop</th>
        <td mat-cell *matCellDef="let row">
          <div *ngIf="row.customDevelopUrlNew">
            <a target="_blank" [href]="row.customDevelopUrlNew">Develop - New</a>
            <div style="height:8px"></div>
          </div>
          <div *ngIf="row.customDevelopUrl">
            <a target="_blank" [href]="row.customDevelopUrl">Develop</a>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="staging">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Staging</th>
        <td mat-cell *matCellDef="let row">
          <div *ngIf="row.customStagingUrlNew">
            <a target="_blank" [href]="row.customStagingUrlNew">Staging - New</a>
            <div style="height:8px"></div>
          </div>
          <div *ngIf="row.customStagingUrl">
            <a target="_blank" [href]="row.customStagingUrl">Staging</a>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="production">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Production</th>
        <td mat-cell *matCellDef="let row">
          <div *ngIf="row.customProductionUrlNew">
            <a target="_blank" [href]="row.customProductionUrlNew">Production - New</a>
            <div style="height:8px"></div>
          </div>
          <div *ngIf="row.customProductionUrl">
            <a target="_blank" [href]="[row.customProductionUrl]">Production</a>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <h3>Cast</h3>

    <table mat-table [dataSource]="dataSourceCast" matSort>
      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
        <td mat-cell *matCellDef="let row">{{ row.id }}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Site</th>
        <td mat-cell *matCellDef="let row">
          <strong>{{ row.name }}</strong>
        </td>
      </ng-container>

      <ng-container matColumnDef="develop">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Develop</th>
        <td mat-cell *matCellDef="let row">
          <div *ngIf="row.customDevelopUrlNew">
            <a target="_blank" [href]="row.customDevelopUrlNew">Develop - New</a>
            <div style="height:8px"></div>
          </div>
          <div *ngIf="row.customDevelopUrl">
            <a target="_blank" [href]="row.customDevelopUrl">Develop</a>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="staging">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Staging</th>
        <td mat-cell *matCellDef="let row">
          <div *ngIf="row.customStagingUrlNew">
            <a target="_blank" [href]="row.customStagingUrlNew">Staging - New</a>
            <div style="height:8px"></div>
          </div>
          <div *ngIf="row.customStagingUrl">
            <a target="_blank" [href]="row.customStagingUrl">Staging</a>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="production">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Production</th>
        <td mat-cell *matCellDef="let row">
          <div *ngIf="row.customProductionUrlNew">
            <a target="_blank" [href]="row.customProductionUrlNew">Production - New</a>
            <div style="height:8px"></div>
          </div>
          <div *ngIf="row.customProductionUrl">
            <a target="_blank" [href]="[row.customProductionUrl]">Production</a>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>


    <h3>Aaptiv</h3>

    <table mat-table [dataSource]="dataSourceAaptiv" matSort>
      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
        <td mat-cell *matCellDef="let row">{{ row.id }}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Site</th>
        <td mat-cell *matCellDef="let row">
          <strong>{{ row.name }}</strong>
        </td>
      </ng-container>

      <ng-container matColumnDef="develop">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Develop</th>
        <td mat-cell *matCellDef="let row">
          <div *ngIf="row.customDevelopUrl">
            <a target="_blank" [href]="row.customDevelopUrl">Develop</a>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="staging">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Staging</th>
        <td mat-cell *matCellDef="let row">
          <div *ngIf="row.customStagingUrl">
            <a target="_blank" [href]="row.customStagingUrl">Staging</a>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="production">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Production</th>
        <td mat-cell *matCellDef="let row">
          <div *ngIf="row.customProductionUrl">
            <a target="_blank" [href]="[row.customProductionUrl]">Production</a>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</div>
